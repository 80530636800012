<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">Items Setup</h3>
      <ul>
        <li>
          <a href="index.html">Items</a>
        </li>
        <li>All Items</li>
      </ul>
    </div>
    <div>-->
    <DataTableComponent :table="table"></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
import ACL from "../../acl";
export default {
  data() {
    return {
      table: {
        modal: "User",
        path: "all_users",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
          },
          {
            text: this.$i18n.t("Mobile"),
            value: "mobile",
          },
          {
            text: this.$i18n.t("Email"),
            value: "email",
          },
          // {
          //   text: this.$i18n.t("Gender"),
          //   value: "gender"
          // },
          // {
          //   text: this.$i18n.t("Date Of Birth"),
          //   value: "date_of_birth",
          //   sortable: false
          // },
          // {
          //   text: this.$i18n.t("Country"),
          //   value: "country",
          //   sortable: false,
          // },
          {
            text: this.$i18n.t("Role"),
            value: "role",
            sortable: false,
          },
          {
            text: this.$i18n.t("Last Login"),
            value: "lastLogin",
            sortable: false,
          },
          {
            text: this.$i18n.t("Show Password"),
            value: "plain_password",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Users"),
        apiEndPoints: {
          list: "users",
          create: "users/store",
          edit: "users/update",
          delete: "users/destroy",
        },
        editedItem: {
          first_name: "",
          last_name: "",
          ar_name: "",
          email: "",
          password: "",
          mobile: "",
          date_of_birth: "",
          country_id: 187,
          roles_ids: [],
          grade_ids: [],
          country_code_id: 187,
        },
        permissions: {
          list: "list-users",
          add: "add-users",
          edit: "edit-users",
          delete: "delete-users",
          change_password: "change_password-users",
          bulkReset: "bulk-reset-users",
          viewPassword: "view-user-password",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {
    if (!ACL.checkPermission(this.table.permissions.viewPassword)) {
      this.table.headers = this.table.headers.filter(function (row) {
        return row.value != "plain_password";
      });
    }
  },
};
</script>

<style></style>
